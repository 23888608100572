import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FaPlus, FaRegBookmark, FaSpinner } from "react-icons/fa6";
import {
  getCompanyEmployee,
  getDepartments,
  getStruct,
} from "../../../services/employee.service";
import { CiTrash } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addSurveys,
  getEmailText,
  getSurveyListById,
  getTopics,
  updateEditedSurvey,
} from "../../../services/survey.service";
import { LuPlay } from "react-icons/lu";
import "../../../css/style.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaCirclePlus } from "react-icons/fa6";
import ReactQuill from "react-quill";
import Loading from "../../../components/uncommon/Loading";

const CreateSurvey = () => {
  const [onEnter, setOnEnter] = useState(0);
  const [onSection, setOnSection] = useState(1);
  const [accordion, setAccordion] = useState(0);
  const [title, setTitle] = useState("");
  const [checkedTopics, setCheckedTopics] = useState([]);
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [initialQuestions, setInitialQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [firstValue, setFirstValue] = useState("");
  const [lastValue, setLastValue] = useState("");
  const [newQuestionText, setNewQuestionText] = useState("");
  const [employees, setEmployees] = useState([]);
  const [checkedEmployees, setCheckedEmployees] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isInfinity, setIsInfinity] = useState(true);
  const [selectedGame, setSelectedGame] = useState(0);
  const { t, i18n } = useTranslation();
  const today = new Date();
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const [allChecked, setAllChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [endDate, setEndDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [repeat, setRepeat] = useState(1);
  const [popUp, setPopUp] = useState(false);
  const [adding, setAdding] = useState(false);
  const [newTopicName, setNewTopicName] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [emailText, setEmailText] = useState("");
  const [initialEmailText, setInitialEmailText] = useState("{{gameUrl}}");
  const [invitedEmp, setInvitedEmp] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  //struct
  const [struct, setStruct] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [alba, setAlba] = useState([]);
  const [teams, setTeams] = useState([]);

  //edit
  const { id } = useParams();
  const isEdit = !!id;
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeData = await getCompanyEmployee();
        setEmployees(employeeData.data);

        const departmentData = await getDepartments();
        setDepartments(departmentData.data);

        const topicData = await getTopics();
        const groupedData = groupDataByTopicId(topicData.data);
        setInitialQuestions(groupedData);
        setQuestions(groupedData);

        const emailTextData = await getEmailText();
        setEmailText(emailTextData.data);
        setInitialEmailText(emailTextData.data);

        const structData = await getStruct();
        setStruct(structData.data);
        setDepartments(structData.data.departments);
        setAlba(structData.data.albas);
        setTeams(structData.data.teams);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (
      // filteredSections.length > 0 &&
      firstValue !== "" &&
      lastValue !== "" &&
      title !== ""
    ) {
      sectionChange(section3Ref);
    }
  }, [title, lastValue, firstValue]);

  useEffect(() => {
    // Handle language switch
    const loadQuestionsForLanguage = () => {
      if (i18n.language === "en") {
        const enTopics = groupDataByTopicId([
          {
            topicId: 1,
            questionText:
              "Our team members are responsible for their job duties.",
            questionId: 323,
            topicName: "Team",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 1,
            questionText: "I feel like I'm part of the team.",
            questionId: 324,
            topicName: "Team",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 1,
            questionText: "I really like the team I work with.",
            questionId: 325,
            topicName: "Team",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 1,
            questionText:
              "The workload is evenly distributed among team members.",
            questionId: 326,
            topicName: "Team",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 2,
            questionText:
              "I feel like I am in the right place in this organization.",
            questionId: 328,
            topicName: "Organizational Culture",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 2,
            questionText: "Our organization has a very good culture.",
            questionId: 329,
            topicName: "Organizational Culture",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 2,
            questionText:
              "The workload is evenly distributed among team members.",
            questionId: 330,
            topicName: "Organizational Culture",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 3,
            questionText:
              "Our team members are responsible for their job duties.",
            questionId: 331,
            topicName: "Work-Life Balance",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 3,
            questionText: "I feel like I'm part of the team.",
            questionId: 332,
            topicName: "Work-Life Balance",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 3,
            questionText: "I really like the team I work with.",
            questionId: 333,
            topicName: "Work-Life Balance",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 3,
            questionText:
              "The workload is evenly distributed among team members.",
            questionId: 334,
            topicName: "Work-Life Balance",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 4,
            questionText:
              "I am satisfied with the decisions made by the organization.",
            questionId: 335,
            topicName: "Leadership",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 4,
            questionText: "I have confidence in the leadership team.",
            questionId: 336,
            topicName: "Leadership",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 4,
            questionText:
              "Our organization communicates well with its employees.",
            questionId: 337,
            topicName: "Leadership",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 4,
            questionText:
              "Our leadership clearly communicates the key business goals to me.",
            questionId: 338,
            topicName: "Leadership",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 4,
            questionText:
              "The workload is evenly distributed among team members.",
            questionId: 339,
            topicName: "Leadership",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 5,
            questionText:
              "Our team members are responsible for their job duties.",
            questionId: 340,
            topicName: "Growth and Learning Opportunities",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 5,
            questionText: "I feel like I'm part of the team.",
            questionId: 341,
            topicName: "Growth and Learning Opportunities",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 5,
            questionText: "I really like the team I work with.",
            questionId: 342,
            topicName: "Growth and Learning Opportunities",
            topicNative: true,
            questionNative: true,
          },
          {
            topicId: 5,
            questionText:
              "The workload is evenly distributed among team members.",
            questionId: 343,
            topicName: "Growth and Learning Opportunities",
            topicNative: true,
            questionNative: true,
          },
        ]);
        setQuestions(enTopics);
      } else if (i18n.language === "mn") {
        setQuestions(initialQuestions);
      }
    };

    loadQuestionsForLanguage();
  }, [i18n.language, initialQuestions]);

  useEffect(() => {
    if (isEdit) {
      getSurveyListById(id)
        .then((data) => {
          setData(data.data);
          setSelectedGame(data.data.gameType);
          setOnSection(3);
          setTitle(data.data.name);
          setFirstValue(data.data.leftText);
          setLastValue(data.data.rightText);
          setInstruction(data.data.instruction);
          setRepeat(data.data.interval);
          setEmailText(data.data.email);
          setInvitedEmp(data.data.surveyEmployees);

          if (data.data.endDate === null) {
            setIsInfinity(true);
          } else {
            setIsInfinity(false);
            setEndDate(data.data.endDate);
          }
          const initialCheckedQuestions = data.data.surveyQuestions.map(
            (question) => ({
              topicId: question.topicId,
              questionId: question.questionId,
            })
          );
          const initialCheckedTopics = [
            ...new Set(
              data.data.surveyQuestions.map((question) => question.topicId)
            ),
          ];

          setCheckedQuestions(initialCheckedQuestions);
          setCheckedTopics(initialCheckedTopics);
        })
        .catch((err) => console.log(err));
    }
  }, [id, isEdit]);

  useEffect(() => {
    if (isEdit && invitedEmp.length > 0 && employees.length > 0) {
      const updatedCheckedEmployees = {};

      invitedEmp.forEach((surveyEmployee) => {
        const employeeExists = employees.some(
          (employee) => employee.id === surveyEmployee.employeeId
        );
        if (employeeExists) {
          updatedCheckedEmployees[surveyEmployee.employeeId] = true;
        }
      });

      setCheckedEmployees((prevCheckedEmployees) => ({
        ...prevCheckedEmployees,
        ...updatedCheckedEmployees,
      }));

      const selectedEmployees = employees.filter(
        (employee) =>
          updatedCheckedEmployees[employee.id] || checkedEmployees[employee.id]
      );
      setSelectedEmployees(selectedEmployees);
    }
  }, [invitedEmp, employees, isEdit]);

  const groupDataByTopicId = (data) => {
    const groupedData = [];
    const topicIds = [...new Set(data.map((item) => item.topicId))];

    topicIds.forEach((topicId) => {
      const questions = data.filter((item) => item.topicId === topicId);
      const { topicName } = questions[0];

      groupedData.push({
        topicId,
        topicName,
        questions,
      });
    });

    return groupedData;
  };

  //department
  const getDepartmentName = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "Unknown Department";
  };

  const employeesByDepartment = employees.reduce((dep, employee) => {
    const departmentId = employee.departmentId;
    const departmentName = getDepartmentName(departmentId);

    if (!dep[departmentName]) {
      dep[departmentName] = [];
    }

    dep[departmentName].push(employee);
    return dep;
  }, {});

  const filteredEmployeesByDepartment = Object.fromEntries(
    Object.entries(employeesByDepartment).map(
      ([departmentName, departmentEmployees]) => [
        departmentName,
        departmentEmployees.filter(
          (employee) =>
            employee.firstName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            employee.lastName.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      ]
    )
  );

  const handleCheckAll = () => {
    const updatedCheckedEmployees = {};
    const newAllChecked = !allChecked;

    employees.forEach((employee) => {
      updatedCheckedEmployees[employee.id] = newAllChecked;
    });

    Object.keys(employeesByDepartment).forEach((departmentName) => {
      updatedCheckedEmployees[departmentName] = newAllChecked;
    });

    setCheckedEmployees(updatedCheckedEmployees);
    setAllChecked(newAllChecked);

    if (newAllChecked) {
      setSelectedEmployees([...employees]);
    } else {
      setSelectedEmployees([]);
    }
  };

  const handleDepartmentCheck = (departmentName) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    const departmentChecked = updatedCheckedEmployees[departmentName];

    if (departmentChecked) {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = false;
      });
    } else {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = true;
      });
    }

    updatedCheckedEmployees[departmentName] = !departmentChecked;
    setCheckedEmployees(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployees(selectedEmployees);
    setAllChecked(selectedEmployees.length === employees.length);
  };

  const handleEmployeeCheck = (employeeId) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    updatedCheckedEmployees[employeeId] = !checkedEmployees[employeeId];
    setCheckedEmployees(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployees(selectedEmployees);
    setAllChecked(selectedEmployees.length === employees.length);
  };

  useEffect(() => {
    const allSelected = employees.every(
      (employee) => checkedEmployees[employee.id]
    );
    setAllChecked(allSelected);
  }, [checkedEmployees, employees]);

  //question
  const handleTopicCheckboxChange = (topicId) => {
    if (checkedTopics.includes(topicId)) {
      // Topic is already checked, uncheck it and all questions under it
      setCheckedTopics((prev) => prev.filter((item) => item !== topicId));
      setCheckedQuestions((prev) =>
        prev.filter((item) => item.topicId !== topicId)
      );
    } else {
      // Topic is not checked, check it and all questions under it
      setCheckedTopics((prev) => [...prev, topicId]);
      // Collect all questions under this topic
      let questionsToCheck = [];
      questions.forEach((item) => {
        if (item.topicId === topicId) {
          item.questions.forEach((question) => {
            questionsToCheck.push({ topicId, questionId: question.questionId });
          });
        }
      });
      setCheckedQuestions((prev) => [...prev, ...questionsToCheck]);
    }
  };

  const handleQuestionCheckboxChange = (topicId, questionId) => {
    const existingIndex = checkedQuestions.findIndex(
      (item) => item.topicId === topicId && item.questionId === questionId
    );

    if (existingIndex !== -1) {
      // Remove if already checked
      setCheckedQuestions((prev) =>
        prev.filter(
          (item) =>
            !(item.topicId === topicId && item.questionId === questionId)
        )
      );
    } else {
      // Add to checkedQuestions
      setCheckedQuestions((prev) => [...prev, { topicId, questionId }]);
      // Ensure the topic is checked when adding a new question
      if (!checkedTopics.includes(topicId)) {
        setCheckedTopics((prev) => [...prev, topicId]);
      }
    }
  };

  const handleAddQuestion = (topicId, topicName) => {
    if (newQuestionText === "") {
      toast.error(t("toast.toastAddQuestion"));
      return;
    } else {
      let maxQuestionId = 0;
      questions.forEach((topic) => {
        topic.questions.forEach((question) => {
          if (question.questionId > maxQuestionId) {
            maxQuestionId = question.questionId;
          }
        });
      });

      const newQuestion = {
        topicNative: true,
        questionNative: false,
        questionId: maxQuestionId + 1,
        questionText: newQuestionText,
        topicId: topicId,
        topicName: topicName,
      };

      setQuestions((prevQuestions) => {
        return prevQuestions.map((topic) => {
          if (topic.topicId === topicId) {
            return {
              ...topic,
              questions: [...topic.questions, newQuestion],
            };
          }
          return topic;
        });
      });

      setCheckedTopics((prevCheckedTopics) => {
        if (!prevCheckedTopics.includes(topicId)) {
          return [...prevCheckedTopics, topicId];
        }
        return prevCheckedTopics;
      });

      setCheckedQuestions((prevCheckedQuestions) => {
        return [
          ...prevCheckedQuestions,
          { topicId: topicId, questionId: newQuestion.questionId },
        ];
      });

      setNewQuestionText("");
    }
  };

  const handleRemoveQuestion = (topicId, questionIdToRemove) => {
    const updatedQuestions = questions.map((topic) => {
      if (topic.topicId === topicId) {
        const updatedTopicQuestions = topic.questions.filter(
          (question) => question.questionId !== questionIdToRemove
        );
        return {
          ...topic,
          questions: updatedTopicQuestions,
        };
      }
      return topic;
    });

    setQuestions(updatedQuestions);

    setCheckedQuestions((prevCheckedQuestions) =>
      prevCheckedQuestions.filter(
        (item) =>
          !(item.topicId === topicId && item.questionId === questionIdToRemove)
      )
    );
  };

  const scrollToDiv = (ref) => {
    // if (ref.current) {
    //   ref.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //   });
    // }
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["clean"], // remove formatting button
  ];

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (i18n.language === "en") {
      return `${months[month]} ${day}, ${year}`;
    } else {
      return `${month} сарын ${day}, ${year}`;
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const now = new Date();

    if (selectedDate <= now) {
      setErrorMessage(t("Survey.chooseFutureTime"));
      setEndDate("");
    } else {
      const formattedDate = formatDateTime(selectedDate);
      setEndDate(formattedDate);
      setErrorMessage("");
    }
  };

  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleRepeatChange = (event) => {
    setRepeat(Number(event.target.value));
  };

  const sectionChange = (ref) => {
    scrollToDiv(ref);
    if (ref === section2Ref) {
      setOnSection(2);
    } else {
      setOnSection(3);
    }
  };

  const handleAddTopic = () => {
    if (newTopicName === "") {
      toast.error(t("Survey.topicAddPlaceHolder "));
      return;
    }
    const newTopic = {
      topicId: questions.length + 1,
      topicName: newTopicName,
      questions: [],
    };

    setQuestions([newTopic, ...questions]);
    setNewTopicName("");
    setAdding(false);
  };

  const getNewTopics = () => {
    const initialTopicIds = initialQuestions.map((topic) => topic.topicId);

    const newTopics = questions.filter(
      (topic) => !initialTopicIds.includes(topic.topicId)
    );

    const checkedQuestionIds = new Set(
      checkedQuestions.map((item) => item.questionId)
    );

    const formattedTopics = newTopics
      .map((topic) => {
        const checkedQuestionsInTopic = topic.questions.filter((question) => {
          return checkedQuestionIds.has(question.questionId);
        });

        if (checkedQuestionsInTopic.length > 0) {
          return {
            topicName: topic.topicName,
            questionName: checkedQuestionsInTopic.map(
              (question) => question.questionText
            ),
          };
        }
        return null;
      })
      .filter((topic) => topic !== null);

    return formattedTopics;
  };

  const createSurvey = async () => {
    setLoading(true);

    try {
      if (selectedGame === 0) {
        toast.error(t("Survey.selectGame"));
        setLoading(false);
        return;
      }

      if (checkedQuestions.length === 0) {
        toast.error(t("Survey.selectTopic"));
        setLoading(false);
        return;
      }

      if (title === "") {
        toast.error(t("Survey.giveNameOnSurvey"));
        setLoading(false);
        return;
      }

      if (firstValue === "" || lastValue === "") {
        toast.error(t("Survey.giveDesc"));
        setLoading(false);
        return;
      }

      if (endDate === "") {
        toast.error(t("Survey.giveEndDate"));
        setLoading(false);
        return;
      }

      if (repeat === null) {
        toast.error(t("Survey.giveFrequence"));
        setLoading(false);
        return;
      }

      const newTopics = getNewTopics();

      const initialTopicIds = initialQuestions.map((topic) => topic.topicId);

      const surveyQuestions = checkedQuestions
        .filter(({ topicId }) => initialTopicIds.includes(topicId))
        .map(({ topicId, questionId }) => {
          const topic = questions.find((item) => item.topicId === topicId);

          if (topic) {
            const question = topic.questions.find(
              (item) => item.questionId === questionId
            );

            if (question) {
              return question.questionNative
                ? { topicId: topic.topicId, questionId: question.questionId }
                : {
                    topicId: topic.topicId,
                    questionText: question.questionText,
                  };
            }
          }
          return null;
        })
        .filter((item) => item !== null);

      const time = getCurrentDateTime();

      if (selectedEmployees.length === 0) {
        toast.error(t("Survey.checkEmployee"));
        setLoading(false);
        return;
      }
      const employeeData = selectedEmployees.map((employee) => ({
        employeeId: employee.id,
        email: employee.email,
      }));

      const emailHasChanged = emailText !== initialEmailText;

      const survey = {
        name: title,
        surveyQuestions: surveyQuestions,
        surveyEmployees: employeeData,
        newTopicQuestions: newTopics,
        gameType: selectedGame,
        leftText: firstValue,
        rightText: lastValue,
        interval: repeat,
        startDate: time,
        endDate: endDate,
        instruction: instruction === "" ? null : instruction,
        email: emailHasChanged ? emailText : null,
      };

      await addSurveys(survey);
      // console.log("success", survey);

      setLoading(false);
      setPopUp(true);
    } catch (error) {
      console.error("Error saving the survey: ", error);
      toast.error(t("toast.Error"));
      setLoading(false);
    }
  };

  const editedSurvey = async () => {
    setLoading(true);

    try {
      const secondEmployeeData = selectedEmployees
        .filter(
          (employee) =>
            !invitedEmp.some(
              (onaEmployee) => onaEmployee.employeeId === employee.id
            )
        )
        .map((employee) => ({
          employeeId: employee.id,
          email: employee.email,
        }));

      const editedSurvey = {
        surveyId: id,
        surveyEmployees: secondEmployeeData,
      };

      await updateEditedSurvey(editedSurvey);

      setLoading(false);
      setPopUp(true);
    } catch (error) {
      toast.error(t("toast.Error"));
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    const gameUrlPlaceholder = "{{gameUrl}}";
    if (value.includes(gameUrlPlaceholder)) {
      setEmailText(value);
    } else {
      setEmailText(value + " " + gameUrlPlaceholder);
    }
  };

  if (isEdit && data === null) {
    return <Loading />;
  }

  return (
    <div className="bg-[#f3f4f8] min-h-screen relative ">
      <div className="w-10/12 pt-11 m-auto">
        <div
          className={`${
            onSection === 1 ? "w-full" : "w-10/12 m-auto"
          } transition-all duration-700`}
        >
          <div className="flex items-center gap-4 ">
            <div
              className={`text-lg px-3.5 py-1 ${
                onSection === 1
                  ? "text-white bg-[#88919F]"
                  : " text-[#AAAAAA] bg-[#EDEDED]"
              } rounded-full`}
            >
              1
            </div>
            <p className="text-xl ">{t("Survey.selectGameType")}</p>
          </div>
          {/* game section */}
          <div className="flex items-center justify-between mt-6 px-6">
            <div
              onMouseEnter={() => {
                setOnEnter(1);
              }}
              onMouseLeave={() => {
                setOnEnter(0);
              }}
              onClick={() => {
                if (!isEdit) {
                  setSelectedGame(1);
                  sectionChange(section2Ref);
                }
              }}
              className={`relative rounded-xl bg-white overflow-hidden shadow-xl ${
                onSection === 1 ? "w-[273px] h-[206px]" : "w-[230px] h-[150px]"
              } ${selectedGame === 1 ? "border-2 border-[#003566]" : ""}`}
            >
              <img src="/survey/survey1.png" alt="img" />
              <div
                className={`bg-white absolute rounded-b-xl transition-transform duration-700 ${
                  onEnter === 1 ? "bottom-1" : "-bottom-1"
                }`}
                style={{
                  transform:
                    onEnter === 1 ? "translateY(0)" : "translateY(50%)",
                }}
              >
                <p
                  className={`ps-2 ${onSection === 2 ? "pb-4" : ""} ${
                    i18n.language === "en" ? "mb-2 " : "pt-2"
                  }`}
                >
                  Emoji
                </p>
                <p
                  className={`pt-2 ps-2 text-xs ${
                    i18n.language === "en" ? "text-center pe-4" : ""
                  }`}
                  style={{
                    transition: "opacity 0.7s ease-in-out",
                    opacity: onEnter === 1 ? "1" : "0",
                  }}
                >
                  {t("Survey.emojiGameDes")}
                </p>
              </div>
            </div>
            <div
              onMouseEnter={() => {
                setOnEnter(2);
              }}
              onMouseLeave={() => {
                setOnEnter(0);
              }}
              onClick={() => {
                if (!isEdit) {
                  setSelectedGame(2);
                  sectionChange(section2Ref);
                }
              }}
              className={`relative rounded-xl bg-white overflow-hidden shadow-xl ${
                onSection === 1 ? "w-[273px] h-[206px]" : "w-[230px] h-[150px]"
              } ${selectedGame === 2 ? "border-2 border-[#003566]" : ""}`}
            >
              <img src="/survey/survey2.png" alt="img" />
              <div
                className={`bg-white absolute rounded-b-xl transition-transform duration-700 ${
                  onEnter === 2 ? "bottom-1" : "-bottom-1"
                }`}
                style={{
                  transform:
                    onEnter === 2 ? "translateY(0)" : "translateY(50%)",
                }}
              >
                <p className="pt-2 ps-2">Zuma</p>
                <p
                  className="pt-2 ps-2 text-xs"
                  style={{
                    transition: "opacity 0.7s ease-in-out",
                    opacity: onEnter === 2 ? "2" : "0",
                  }}
                >
                  {t("Survey.zumaGameDes")}
                </p>
              </div>
            </div>
            <div
              onMouseEnter={() => {
                setOnEnter(3);
              }}
              onMouseLeave={() => {
                setOnEnter(0);
              }}
              onClick={() => {
                if (!isEdit) {
                  setSelectedGame(3);
                  sectionChange(section2Ref);
                }
              }}
              className={`relative rounded-xl bg-white overflow-hidden shadow-xl ${
                onSection === 1 ? "w-[273px] h-[206px]" : "w-[230px] h-[150px]"
              } ${selectedGame === 3 ? "border-2 border-[#003566]" : ""}`}
            >
              <img src="/survey/survey3.png" alt="img" />
              <div
                className={`bg-white absolute rounded-b-xl transition-transform duration-700 ${
                  onEnter === 3 ? "bottom-1" : "-bottom-3"
                }`}
                style={{
                  transform:
                    onEnter === 3 ? "translateY(0)" : "translateY(50%)",
                }}
              >
                <p
                  className={`pt-2 ps-2 ${
                    i18n.language === "en" ? "mb-4" : ""
                  }`}
                >
                  Runner
                </p>
                <p
                  className="pt-2 ps-2 text-xs"
                  style={{
                    transition: "opacity 0.7s ease-in-out",
                    opacity: onEnter === 3 ? "3" : "0",
                  }}
                >
                  {t("Survey.runnerGameDes")}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* question section */}
        <div ref={section2Ref} />
        <div
          className={`${
            onSection === 2 ? "w-full" : "w-10/12 m-auto"
          } mt-10 transition-all duration-700`}
        >
          <div className="flex items-center gap-4">
            <div
              className={`text-lg px-3.5 py-1 ${
                onSection === 2
                  ? "text-white bg-[#88919F]"
                  : " text-[#AAAAAA] bg-[#EDEDED]"
              } rounded-full`}
            >
              2
            </div>
            <p className="text-xl ">{t("Survey.selectQuestionType")}</p>
          </div>
          <div className="mt-6 justify-between gap-2 flex items-start">
            <div className="w-[60%]">
              <div className="bg-white px-6 rounded-xl mb-4">
                {adding && isEdit === false ? (
                  <div className="flex items-center gap-2">
                    <button onClick={handleAddTopic} className="text-xl">
                      <FaCirclePlus />
                    </button>
                    <input
                      className="rounded-xl border-none w-full py-4 focus:none"
                      type="text"
                      onChange={(e) => {
                        setNewTopicName(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddTopic();
                        }
                      }}
                      placeholder={t("Survey.topicAddPlaceHolder")}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setAdding(true);
                    }}
                    className="flex justify-between items-center cursor-pointer"
                  >
                    <div className="flex items-center gap-2 mt-4 w-full mb-4">
                      <button className="text-lg">
                        <FaCirclePlus />
                      </button>
                      <div className="flex items-center w-full justify-between">
                        <p>{t("Survey.topicAdd")}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {isEdit && data.newTopicQuestions.length > 0 && (
                <div>
                  {data.newTopicQuestions.map((items) => {
                    return (
                      <div
                        key={items.topicId}
                        className="bg-white px-6 rounded-xl mb-4"
                      >
                        <div className="flex justify-between items-center cursor-pointer transition-all duration-700">
                          <div className="flex items-center gap-2 mt-4 w-full">
                            <input
                              type="checkbox"
                              className="rounded bg-[#EBEBEB] border-[#BCBCBC]"
                              checked={true}
                            />
                            <div
                              onClick={() => {
                                accordion === items.topicId
                                  ? setAccordion(0)
                                  : setAccordion(items.topicId);
                              }}
                              className="flex items-center w-full justify-between"
                            >
                              <p>{items.topicName}</p>
                              <IoIosArrowDown
                                className={` duration-700 transition-transform ${
                                  accordion === items.topicId
                                    ? "rotate-180"
                                    : ""
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`px-6 mt-4 overflow-hidden transition-max-height duration-700 ${
                            accordion === items.topicId ? "max-h-96" : "max-h-0"
                          }`}
                        >
                          {items.questionName?.length !== 0 ? (
                            <div>
                              {items.questionName &&
                                items.questionName.map((question, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="flex items-center justify-between mb-4"
                                    >
                                      <div className="flex items-center gap-2">
                                        <input
                                          type="checkbox"
                                          className="rounded bg-[#EBEBEB] border-[#BCBCBC]"
                                          checked={true}
                                        />
                                        <p className="leading-4 text-sm">
                                          {question}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}

                              {/* <div className="relative mb-8">
                                <div className="flex items-center gap-2">
                                  <button
                                    onClick={() => {
                                      handleAddQuestion(
                                        items.topicId,
                                        items.topicName
                                      );
                                    }}
                                    className="flex items-center"
                                  >
                                    <FaPlus />
                                  </button>
                                  <div className="border-b border-[#9A9A9A] w-full">
                                    <input
                                      className="border-none p-0 w-full text-[#9A9A9A] rounded-sm"
                                      type="text"
                                      placeholder={t(
                                        "Survey.questionAddPlaceHolder"
                                      )}
                                      value={newQuestionText}
                                      onChange={(e) => {
                                        if (newQuestionText !== "") {
                                          setNewQuestionText("");
                                        }
                                        setNewQuestionText(e.target.value);
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          handleAddQuestion(
                                            items.topicId,
                                            items.topicName
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <p className="text-[10px] absolute -bottom-4 left-6">
                                  {t("Survey.scale1to5")}
                                </p>
                              </div> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {questions?.map((items) => {
                const isTopicChecked = checkedTopics.includes(items.topicId);

                return (
                  <div
                    key={items.topicId}
                    className="bg-white px-6 rounded-xl mb-4"
                  >
                    <div className="flex justify-between items-center cursor-pointer transition-all duration-700">
                      <div className="flex items-center gap-2 mt-4 w-full">
                        <input
                          type="checkbox"
                          className="rounded bg-[#EBEBEB] border-[#BCBCBC]"
                          checked={isTopicChecked}
                          disabled={isEdit}
                          onChange={() =>
                            handleTopicCheckboxChange(items.topicId)
                          }
                        />
                        <div
                          onClick={() => {
                            accordion === items.topicId
                              ? setAccordion(0)
                              : setAccordion(items.topicId);
                          }}
                          className="flex items-center w-full justify-between"
                        >
                          <p>{items.topicName}</p>
                          <IoIosArrowDown
                            className={` duration-700 transition-transform ${
                              accordion === items.topicId ? "rotate-180" : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`px-6 mt-4 overflow-hidden transition-max-height duration-700 ${
                        accordion === items.topicId ? "max-h-96" : "max-h-0"
                      }`}
                    >
                      {items.question?.length !== 0 ? (
                        <div>
                          {items.questions &&
                            items.questions.map((question) => {
                              const isChecked = checkedQuestions.some(
                                (item) =>
                                  item.topicId === items.topicId &&
                                  item.questionId === question.questionId
                              );

                              return (
                                <div
                                  key={question.questionId}
                                  className="flex items-center justify-between mb-4"
                                >
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="checkbox"
                                      className="rounded bg-[#EBEBEB] border-[#BCBCBC]"
                                      checked={isChecked}
                                      disabled={isEdit}
                                      onChange={() =>
                                        handleQuestionCheckboxChange(
                                          items.topicId,
                                          question.questionId
                                        )
                                      }
                                    />
                                    <p className="leading-4 text-sm">
                                      {question.questionText}
                                    </p>
                                  </div>
                                  {question.questionNative === false && (
                                    <button
                                      disabled={isEdit}
                                      onClick={() =>
                                        handleRemoveQuestion(
                                          items.topicId,
                                          question.questionId
                                        )
                                      }
                                    >
                                      <CiTrash />
                                    </button>
                                  )}
                                </div>
                              );
                            })}

                          <div className="relative mb-8">
                            <div className="flex items-center gap-2">
                              <button
                                onClick={() => {
                                  handleAddQuestion(
                                    items.topicId,
                                    items.topicName
                                  );
                                }}
                                disabled={isEdit}
                                className="flex items-center"
                              >
                                <FaPlus />
                              </button>
                              <div className="border-b border-[#9A9A9A] w-full">
                                <input
                                  className="border-none p-0 w-full text-[#9A9A9A] rounded-sm"
                                  type="text"
                                  disabled={isEdit}
                                  placeholder={t(
                                    "Survey.questionAddPlaceHolder"
                                  )}
                                  value={newQuestionText}
                                  onChange={(e) => {
                                    if (newQuestionText !== "") {
                                      setNewQuestionText("");
                                    }
                                    setNewQuestionText(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleAddQuestion(
                                        items.topicId,
                                        items.topicName
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <p className="text-[10px] absolute -bottom-4 left-6">
                              {t("Survey.scale1to5")}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-[38%]">
              <div className="px-2 py-3 bg-white rounded-xl h-[250px] overflow-y-auto">
                <div className="flex justify-between items-center">
                  <p
                    className={`${onSection === 2 ? "text-base" : " text-sm"}`}
                  >
                    {t("Survey.selectedQuestions")}
                  </p>
                  <p
                    className={`text-[#64748B] ${
                      onSection === 2 ? "text-xs" : "text-[10px]"
                    }`}
                  >
                    {t("Dashboard.Total")}: {checkedQuestions.length}{" "}
                    {t("Dashboard.survey")}
                  </p>
                </div>
                <div className="mt-3">
                  {checkedQuestions.length !== 0 ? (
                    <div>
                      {checkedTopics.map((topicId) => {
                        const topic = questions.find(
                          (item) => item.topicId === topicId
                        );

                        if (!topic) {
                          return null;
                        }

                        const selectedQuestions = checkedQuestions
                          .filter((item) => item.topicId === topicId)
                          .map((item) => {
                            const question = topic.questions.find(
                              (q) => q.questionId === item.questionId
                            );

                            if (question) {
                              return question.questionText;
                            }

                            return null;
                          })
                          .filter(Boolean);

                        return (
                          <div key={topicId} className="mb-4">
                            <p className="font-semibold text-xs">
                              {topic.topicName}
                            </p>
                            <ul className="list-decimal ps-4 text-[11px] text-[#636466] font-semibold mt-2">
                              {selectedQuestions.map((question, index) => (
                                <li key={index}>{question}</li>
                              ))}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mt-4">
                      <Skeleton baseColor="#D6D6D6" width={"40%"} />
                      <Skeleton baseColor="#EDEDED" count={3} />
                      <Skeleton
                        className="mt-4"
                        baseColor="#D6D6D6"
                        width={"40%"}
                      />
                      <Skeleton baseColor="#EDEDED" count={2} />
                    </div>
                  )}
                </div>
              </div>
              <div className="px-2 py-3 bg-white rounded-xl mt-5">
                <p className="font-semibold text-[#1E1E1E]">
                  {t("Survey.nameSurvey")}
                </p>
                <input
                  className="rounded-lg w-full mt-3 border-[#D4D4D4] text-xs"
                  placeholder={t("Survey.writeHereInput")}
                  type="text"
                  value={title}
                  disabled={isEdit}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className="px-2 py-3 bg-white rounded-xl mt-5">
                <p className="text-center">{t("Survey.name2Poles")}</p>
                <div className="mt-10">
                  <div className="flex justify-center">
                    <div className="relative flex justify-center items-end w-[240px]">
                      <p className="absolute -top-5 left-0">1</p>
                      <p className="absolute -top-5 right-0">5</p>
                      <img
                        className="flex justify-center"
                        src="/survey/line.png"
                        alt="line"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4 gap-2">
                    <input
                      className="border border-[#D4D4D4] bg-white w-[60%] text-xs text-[#64748B] rounded-xl px-1 py-2"
                      placeholder={t("Survey.firstInputPlace")}
                      value={firstValue}
                      disabled={isEdit}
                      onChange={(e) => {
                        setFirstValue(e.target.value);
                      }}
                    />
                    <input
                      className="border border-[#D4D4D4] bg-white w-[40%] text-xs text-[#64748B] rounded-xl px-1 py-2"
                      placeholder={t("Survey.lastInputPlace")}
                      value={lastValue}
                      disabled={isEdit}
                      onChange={(e) => {
                        setLastValue(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="px-2 py-3 bg-white rounded-xl mt-5">
                <p className="font-semibold text-[#1E1E1E]">
                  {t("Survey.instruction")}
                </p>
                <textarea
                  className="rounded-lg w-full mt-3 border-[#D4D4D4] text-xs"
                  placeholder={t("Survey.writeHereInput")}
                  disabled={isEdit}
                  value={instruction}
                  onChange={(e) => {
                    setInstruction(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          ref={section3Ref}
          className={`${
            onSection === 3 ? "w-full" : "w-10/12 m-auto"
          } mt-10 pb-10 transition-all duration-700`}
        >
          <div className="flex items-center gap-4">
            <div
              className={`text-lg px-3.5 py-1 ${
                onSection === 3
                  ? "text-white bg-[#88919F]"
                  : " text-[#AAAAAA] bg-[#EDEDED]"
              } rounded-full`}
            >
              3
            </div>
            <p className="text-xl ">{t("Survey.selectFrequencyAndEmp")}</p>
          </div>
          <div className="gap-2 flex justify-between items-start mt-6">
            <div className="w-[50%]">
              <div className="bg-white rounded-xl px-8 py-5 h-[600px] overflow-y-auto">
                <p className="text-[#444444]">{t("Survey.selectEmp")}.</p>
                <div className="flex items-center justify-between my-4 gap-2">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search by first or last name"
                    className="p-0 rounded-xl px-2 py-1"
                  />
                  <div className="flex justify-end gap-2 items-center my-3">
                    <input
                      className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                      type="checkbox"
                      onClick={handleCheckAll}
                      checked={allChecked}
                    />
                    <p className="text-[#1E1E1E] text-xs">
                      {t("Survey.selectAll")}
                    </p>
                  </div>
                </div>
                <div>
                  {Object.entries(filteredEmployeesByDepartment).map(
                    ([departmentName, departmentEmployees]) => (
                      <div className="mb-4" key={departmentName}>
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleDepartmentCheck(departmentName)
                            }
                            checked={checkedEmployees[departmentName] || false}
                            className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                          />
                          <p className="text-sm font-semibold text-[#1E1E1E] ms-2 ">
                            {departmentName}
                          </p>
                        </div>
                        {departmentEmployees.map((employee) => (
                          <div
                            className="ms-4 flex items-center mt-3"
                            key={employee.id}
                          >
                            <div>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleEmployeeCheck(employee.id)
                                }
                                disabled={
                                  invitedEmp.some(
                                    (invited) =>
                                      invited.employeeId === employee.id
                                  ) && isEdit
                                }
                                checked={checkedEmployees[employee.id] || false}
                                className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                              />
                              <label className="ms-2 text-sm text-[#1E1E1E]">
                                {employee.lastName}, {employee.firstName}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="w-[45%]">
              <div className="bg-white rounded-xl px-3 py-5">
                <p className="text-[#444] mb-4">
                  {t("Survey.selectFrequencyDate")}
                </p>
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <p className="text-sm font-bold">
                      {t("Survey.frequencyStartDate")}
                    </p>
                    <p className="text-xs">
                      {isEdit ? data?.startDate : formatDate(today)}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mb-4">
                    <p className="text-sm font-bold">
                      {t("Survey.questionFrequency")}
                    </p>
                    <select
                      className="border-none rounded-lg text-xs "
                      onChange={handleRepeatChange}
                      value={repeat}
                      disabled={isEdit}
                    >
                      <option selected value="1">
                        {t("Survey.oneTimeFrequency")}
                      </option>
                      <option value="14">
                        {t("Survey.weekTimeFrequency")}
                      </option>
                      <option value="30">
                        {t("Survey.monthTimeFrequency")}
                      </option>
                      <option value="15">
                        {t("Survey.seasonsTimeFrequency")}
                      </option>
                      <option value="">
                        {t("Survey.halfYearTimeFrequency")}
                      </option>
                      <option value="365">
                        {t("Survey.yearTimeFrequency")}
                      </option>
                    </select>
                  </div>
                  <p className="text-sm font-bold">
                    {t("Survey.frequencyEndDate")}:
                  </p>
                  <input
                    type="date"
                    className={`w-full rounded-xl border-[#D7D7D7] mt-3 transition-all duration-700 transform ${
                      isInfinity
                        ? "opacity-0 scale-95"
                        : "opacity-100 scale-100"
                    } ${isInfinity ? "hidden" : "block"}`}
                    onChange={handleDateChange}
                    disabled={isEdit}
                  />
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}

                  <div className="flex items-center gap-2 mt-3 ms-3">
                    <input
                      className="bg-[#EBEBEB] border-[#BCBCBC] rounded"
                      type="checkbox"
                      value={isInfinity}
                      checked={isInfinity}
                      disabled={isEdit}
                      onChange={() => {
                        setIsInfinity(!isInfinity);
                        setEndDate(null);
                      }}
                    />
                    <p className="text-xs text-[#1e1e1e]">
                      {t("Survey.infiniteContinue")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white mt-6 px-3 py-5 rounded-xl">
            <p className="mt-2 mb-2 font-bold text-center text-[14px]">
              {t("Assessment.pleaseChangeEmail")}
            </p>
            <ReactQuill
              className="text-sm"
              theme="snow"
              modules={{ toolbar: toolbarOptions }}
              value={emailText}
              disabled={isEdit}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="flex justify-end pb-10 gap-7">
          {/* <div className="flex flex-col items-center">
            <button
              onClick={createSurvey}
              className={` rounded-xl bg-[#E4FFEB] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner animate-spin">
                  <FaSpinner />
                </span>
              ) : (
                <span>
                  <FaRegBookmark className="text-[#00962A] h-[18px] w-[14px]" />
                </span>
              )}
            </button>
            <p className="text-sm text-[#00962A] text-semibold text-center mt-1">
              {loading ? "Уншиж байна" : "Илгээх"}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={createSurvey}
              className={` rounded-xl bg-[#FFEFE3] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner animate-spin">
                  <FaSpinner />
                </span>
              ) : (
                <span>
                  <LuPlay className="text-[#FF6B00] h-[18px] " />
                </span>
              )}
            </button>
            <p className="text-sm text-[#FF6B00] text-semibold text-center mt-1">
              {loading ? "Уншиж байна" : "Туршиж үзэх"}
            </p>
          </div> */}
          <div className="flex flex-col items-center">
            <button
              onClick={isEdit ? editedSurvey : createSurvey}
              className={` rounded-xl bg-[#E3EBFF] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner">
                  <FaSpinner className="spin-animation" />
                </span>
              ) : (
                <span>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 5.77L12.28 16.2L8.81 14.67L8 14.31L7.19 14.67L3.72 16.2L8 5.77ZM8 0.5L0.5 18.79L1.21 19.5L8 16.5L14.79 19.5L15.5 18.79L8 0.5Z"
                      fill="#084DFF"
                    />
                  </svg>
                </span>
              )}
            </button>
            <p className="text-sm text-[#084DFF] text-semibold text-center mt-1">
              {loading ? t("Survey.loading") : t("Survey.send")}
            </p>
          </div>
        </div>
      </div>
      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl p-6 w-[548px] h-[329px] flex flex-col justify-center">
            <div className="flex items-center justify-center">
              <img src="/survey/surveyPop.png" alt="pop" />
            </div>
            <div className="text-center mt-4">
              <p className="text-[24px] font-bold text-[#444]">
                {t("toast.publishedSuccess")}!
              </p>
              <p className="text-[#616161] text-[20px] mt-2">
                {t("Survey.forSelectedEmp")} <br /> {t("Survey.successfulSent")}
                .
              </p>
              <button
                onClick={() => {
                  navigate("/company/newSurvey");
                }}
                className="mt-4 px-8 py-2 bg-[#222] text-lg rounded-xl text-white"
              >
                {t("Survey.end")}
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default CreateSurvey;
