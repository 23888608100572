import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getOnboardList } from "../../services/onboard.service";
import Loading from "../../components/uncommon/Loading";
import { MdArrowForwardIos } from "react-icons/md";

const OnBoarding = () => {
  const { t } = useTranslation();
  const [list, setList] = useState(null);
  const height = window.innerHeight - 250;
  const navigate = useNavigate();
  const [hover, setHover] = useState(0);

  useEffect(() => {
    getOnboardList()
      .then((data) => {
        setList(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  if (list === null) {
    return <Loading />;
  }

  return (
    <div className="pt-11 w-10/12 m-auto">
      <div className="flex items-center justify-between">
        <p className="text-[24px] text-[#222222]">Дасан зохицох</p>
        <button
          onClick={() => {
            navigate("create");
          }}
          className="px-6 py-2 rounded-lg bg-[#324D72] text-[#fff] flex items-center gap-2 font-semibold text-xs"
        >
          Шинээр үүсгэх <FaPlus />
        </button>
      </div>
      <div className="flex items-center w-full gap-10 mt-4">
        <div className="w-[50%] overflow-y-scroll">
          <p className="text-[#222] text-lg font-semibold mb-4">
            Явагдаж байгаа асуумжууд
          </p>
          <div style={{ height: `${height}px` }}>
            {list.length === 0 ? (
              <div>Doesnt create any onboard</div>
            ) : (
              <div className="flex flex-col gap-8 mb-10">
                {[...list].reverse().map((items) => {
                  return (
                    <div
                      key={items.id}
                      className="shadow-md px-6 py-2 w-full  rounded-xl "
                      onMouseEnter={() => {
                        setHover(items.id);
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <p className="text-sm text-[#1E293B] font-semibold">
                            {items.name}
                          </p>
                          <p className="text-[#636466] text-xs">
                            {items.createdDate === null
                              ? "0000/00/00"
                              : items.createdDate.substring(0, 10)}
                          </p>
                        </div>
                        <div>
                          <p className="text-[#636466 text-xs">Оролцогчид</p>
                          <div className="flex items-center gap-2">
                            <img src="/onboard/teams.svg" alt="icon" />
                            <p className="text-xs text-[#1E293B] font-semibold">
                              {items.employeeCount}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="flex items-center justify-between gap-2 mt-3">
                    <div className="flex items-center gap-2 bg-[#F4F6FB] py-0.5 px-2 rounded-md">
                      <img src="/onboard/comPhone.svg" alt="icon" />
                      <p className="text-[#324D72] text-xs">Хосолсон</p>
                    </div>
                    <div className="flex items-center gap-2 bg-[#F4F6FB] py-0.5 px-2 rounded-md">
                      <img src="/onboard/user.svg" alt="icon" />
                      <p className="text-[#324D72] text-xs">Шинэ ажилтан</p>
                    </div>
                    <div className="flex items-center gap-2 bg-[#F4F6FB] py-0.5 px-2 rounded-md">
                      <img src="/onboard/list.svg" alt="icon" />
                      <p className="text-[#324D72] text-xs">43 даалгавар</p>
                    </div>
                  </div> */}
                      <div className="flex items-center justify-between">
                        <div className="mt-3 flex  items-center gap-2 bg-[#FFF7EE] py-0.5 px-2 rounded-md">
                          <img src="/onboard/coin.svg" alt="icon" />
                          <p className="text-[#D79511] text-xs ">
                            {items.score === null ? 0 : items.score}
                          </p>
                        </div>
                        {hover === items.id && (
                          <button
                            onClick={() => {
                              navigate(`create/${items.id}`);
                            }}
                            className="text-[#222] font-semibold text-opacity-70 text-sm flex items-center gap-2"
                          >
                            Дэлгэрэнгүй <MdArrowForwardIos />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnBoarding;
