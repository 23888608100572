import api from "../helper/request";

const createOnboard = (obj) => api.post(`/onboard/create`, obj);

const getOnboardList = () => api.get(`/onboard`);

const editOnboard = (id) => api.get(`/onboard/detail?onboardId=${id}`);

const editedOnboard = (obj) => api.post(`/onboard/assign-emp`, obj);

export { createOnboard, getOnboardList, editOnboard, editedOnboard };
