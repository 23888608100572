import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Modal, linearProgressClasses } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import ReactPlayer from 'react-player/vimeo';
import { useLocation, useNavigate } from "react-router-dom";
import Unity, { UnityContext } from "react-unity-webgl";
import "./../../App.css";
import { createMissingScores, createScore } from "../../services/game.service";
import { completePleyer } from "../../services/assessmentPlayer.service";
import { Trans, useTranslation } from "react-i18next";


const steps = [
  "Тоглоом 1",
  "Тоглоом 2",
  "Тоглоом 3",
  "Тоглоом 4",
  "Тоглоом 5",
  "Тоглоом 6",
  "Тоглоом 7",
  "Тоглоом 8",
];

const stepsRu = [
  "1 Игра",
  "2 Игра",
  "3 Игра",
  "4 Игра",
  "5 Игра",
  "6 Игра",
  "7 Игра",
  "8 Игра",
];

const stepsEn = [
  "Game 1",
  "Game 2",
  "Game 3",
  "Game 4",
  "Game 5",
  "Game 6",
  "Game 7",
  "Game 8",
];


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#30e83c" : "#30e880",
  },
}));

const metacogContext = new UnityContext({
  productName: "Games",
  companyName: "Metacog",

  // loaderUrl: "../../../unity/MetaCog/MetaCog.loader.js",
  // dataUrl: "../../../unity/MetaCog/MetaCog.data",
  // frameworkUrl: "../../../unity/MetaCog/MetaCog.framework.js",
  // codeUrl: "../../../unity/MetaCog/MetaCog.wasm",

  loaderUrl: "../../../unity/test/unityTest.loader.js",
  dataUrl: "../../../unity/test/unityTest.data",
  frameworkUrl: "../../../unity/test/unityTest.framework.js",
  codeUrl: "../../../unity/test/unityTest.wasm",
});

// public enum GameTp { Arrows, Switch, Shop, Sheep, Team, cards, BottleGame, TrustGame }

const games = [
  "ARROWS",
  "SWITCH",
  "DELIVERY",
  "NOMADLIFE",
  "TEAM",
  "CARDS",
  "WATERWELL",
  "NEIGHBORS",
];

// arrow, cards, delivery, nomad, neoghbors, water, team, switch
const order = [0, 5, 2, 3, 7, 6, 4, 1];

function GameFlow() {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const [playCards, setPlayCards] = useState(true);

  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(true);
  const [gameOrder, setGameOrder] = useState(0);
  const [video, setVideo] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showModal, setShowModal] = useState(false);
  const [orientation, setOrientation] = useState('portrait');
  const [isRotate, setIsRotate] = useState(false)
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);


  // const [gameScores, setGameScores] = useState([]);

  const updateOrientation = () => {
    setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
    setViewportHeight(window.innerHeight);
    if (window.innerWidth > window.innerHeight) {
      setIsRotate(true)
    }
  };

  useEffect(function () {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('resize', updateOrientation);
    updateOrientation();

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const handleBeforeUnload = (event) => {
      localStorage.setItem('refresh', 'true');

      event.preventDefault(); // Cancel the default behavior
      // Display a custom message in the confirmation dialog
      event.returnValue = 'Are you sure you want to leave?';
    };

    // Add the event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.alert = function () { };

    // If this is a refresh navigation
    if (localStorage.getItem('refresh') === 'true') {
      navigate("/NotFound");
      localStorage.removeItem('refresh');  // Reset refresh flag
      return;
    }

    if (location && location.state && location.state.userId != null) {
      completeGame(location?.state?.playedGame || 0);
    } else {
      navigate("/NotFound");
    }

    // Cleanup function
    return () => {
      // Remove the event listener when the component unmount
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [progress]);

  const completeGame = async (playedGame) => {
    let st = playedGame;
    if (playedGame != 0) {
      setVideo(false);
      setShowModal(true);
    }

    metacogContext.on("progress", (progression) => {
      // Now we can use the progression to for example
      // display it on our React app.
      setProgress(progression * 100);
      if (progression === 1) {
        if (i18n.language === "rn")
          metacogContext.send("MetaCog", "GameLang", `${order[playedGame]},1`);
        else if (i18n.language === "en")
          metacogContext.send("MetaCog", "GameLang", `${order[playedGame]},2`);
        else
          metacogContext.send("MetaCog", "GameLang", `${order[playedGame]},0`);
        setStep(st);
      }
    });

    let gameScores = [];

    metacogContext.on("GameOver1", async function (result) {
      gameScores = [...gameScores,
      {
        assessmentPlayerId: parseInt(location.state.userId),
        gameCode: games[order[st]],
        values: result,
      }];

      if (st < 7) {
        createScore({
          assessmentPlayerId: location.state.userId,
          gameCode: games[order[st]],
          values: result,
        })
          .then((res) => {
          })
          .catch((error) => {
            console.log("Error on creating scores")
          });

        st = st + 1;
        if (i18n.language === "rn")
          metacogContext.send("MetaCog", "GameLang", `${order[st]},1`);
        else if (i18n.language === "en")
          metacogContext.send("MetaCog", "GameLang", `${order[st]},2`);
        else
          metacogContext.send("MetaCog", "GameLang", `${order[st]},0`);
        setStep(st);

      } else {
        await createScore({
          assessmentPlayerId: location.state.userId,
          gameCode: games[order[st]],
          values: result,
        })
          .then((res) => {

          })
          .catch((error) => {
            console.log("Error on creating scores")
          });
        await createMissingScores(gameScores);
        await metacogContext.removeAllEventListeners();
        navigate(`/congratz`);
      }
    });
  }
  return (
    <div >
      {
        orientation === 'portrait' && isRotate === false
          ? <div className="flex flex-col items-center justify-center h-full w-full top-[250px]">
            <img src="/assets/rotate.webp" alt="" />
            <p>Сайн байна уу</p>
            <p>Та утсаа эргүүлнэ үү!</p>
            <p>Баярлалаа</p>
          </div>
          :
          <div className="wrapper flex h-screen grid grid-rows-8 bg-[#f6f7f8]">
            <Dialog
              open={showModal}
              onClose={() => {
                setShowModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('GameFlow.doYouContinue')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('GameFlow.continueText')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  setShowModal(false);
                }} autoFocus>
                  {t('GameFlow.continue')}
                </Button>
              </DialogActions>
            </Dialog>
            <div className="row-span-1 flex justify-center items-center z-30">
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={step} alternativeLabel>
                  {
                    i18n.language === "rn" ?
                      stepsRu.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      )) :
                      i18n.language === "en" ?
                        stepsEn.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        )) :
                        steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))
                  }

                </Stepper>
              </Box>
            </div>
            {/* <div className={"grid"}> */}
            <div className="row-span-7 m-auto">
              <div
                className={`pb-14 pl-8 pr-8  ${show === true
                  ? "bg-[#f6f7f8] bg-clip-padding pt-20"
                  : "bg-white pt-8 shadow-xl bg-clip-padding"
                  }  `}
              >
                {playCards ? (
                  <div className="unity-wrapper flex justify-center">
                    <Unity className="unity-canvas" unityContext={metacogContext} />
                  </div>
                ) : null}
              </div>
            </div>

            {show === true ? (
              <div
                className={
                  " top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/3 w-screen h-screen bg-[#f6f7f8] z-20 fixed"
                }
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                  <div className=" text-center text-lg">
                    {t('GameFlow.loading')}
                    <div className="font-bold">{progress.toFixed(2)}%</div>{" "}
                  </div>
                  <div style={{ width: 350 }} className="mt-4">
                    <BorderLinearProgress variant="determinate" value={progress} />
                  </div>
                </div>
              </div>
            ) : null}

            {video === true ? (
              <div
                className={
                  "top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/3 w-screen h-screen bg-[#f6f7f8] z-30 fixed"
                }
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center px-4">
                  <div className="pb-4 text-lg md:text-2xl">
                    {i18n.language === "rn" ? (
                      <>
                        Закончив просмотр видео-инструкции, нажмите{" "}
                        <button
                          className="font-semibold"
                          style={{ color: "#FFC5A8" }}
                          onClick={() => {
                            setVideo(false);
                          }}
                        >
                          ЗДЕСЬ
                        </button>
                      </>
                    ) : i18n.language === "en" ? (
                      <>
                        If you have finished watching the video instructions, click{" "}
                        <button
                          className="font-semibold"
                          style={{ color: "#FFC5A8" }}
                          onClick={() => {
                            setVideo(false);
                          }}
                        >
                          HERE
                        </button>
                      </>
                    ) : (
                      <>
                        Та видео зааврыг үзэж дууссан бол{" "}
                        <button
                          className="font-semibold"
                          style={{ color: "#FFC5A8" }}
                          onClick={() => {
                            setVideo(false);
                          }}
                        >
                          ЭНД
                        </button>{" "}
                        дарна уу.
                      </>
                    )}
                  </div>

                  {/* Responsive ReactPlayer with different URLs */}
                  <div className="flex justify-center">
                    {i18n.language === "rn" ? (
                      <ReactPlayer
                        url="https://vimeo.com/859366747"
                        playing
                        width="100%"
                        height="auto"
                        className="max-w-screen-md"
                        onEnded={() => {
                          setVideo(false);
                        }}
                      />
                    ) : i18n.language === "en" ? (
                      <ReactPlayer
                        url="https://vimeo.com/892519613"
                        playing
                        width="100%"
                        height="auto"
                        className="max-w-screen-md"
                        onEnded={() => {
                          setVideo(false);
                        }}
                      />
                    ) : (
                      <ReactPlayer
                        url="https://vimeo.com/836379695"
                        playing
                        width="450px"
                        height="320px"
                        className="max-w-screen-md"
                        onEnded={() => {
                          setVideo(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
      }
    </div>

  );
}

export default GameFlow;
